import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import HomeIntro from '../components/homeIntro'
import SkillList from '../components/skillList'
import Seo from '../components/seo'

export default function Home({ data }) {
  const AboutLink = {
    textAlign: 'center',
    fontFamily: 'rama-gothic-m, sans-serif',
    fontSize: '3rem',
    margin: '3rem auto',
    textDecoration: 'underline'

  }
  return (
    <Layout pageTitle={Home} >
      <Seo title='Home' />
      <HomeIntro />
      <SkillList />
      <div style={{display: 'flex'}}>
        <Link to='/about' className='link' style={AboutLink}>More About Andrea</Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
    `