import * as React from 'react'
import * as styles from '../stylesheets/homeIntro.module.css'
import { StaticImage } from "gatsby-plugin-image"
import { RiAmazonFill } from "react-icons/ri";

const HomeIntro = () => {
    return (
        <div className={styles.container}>
            <div className={styles.homeIntro} >
                <h1>Actively helping our communities</h1>
            </div>
            <div className={styles.bio}>
                <h2>legal attorney's assistant</h2>
                <StaticImage className={styles.profile} src='../images/andrea-congress.png' alt="andrea at the capitol" />
                <RiAmazonFill className={styles.amazonIcon} />
                <p>Amazon Legal Assistant with a background in immigration law with a Bachelor of Arts Degree from Cal State 
                    Long Beach. Andrea is eager to help the communities most in need and looks forward to attending Law School in 
                    the future.</p>
            </div>
        </div>
    )
}

export default HomeIntro;